<template>
  <v-container fluid>
    <v-widget title="Empresas">
      <template v-slot:toolbar>
        <v-container fluid>
          <v-row dense>
            <v-col cols="10" sm="5">
              <v-text-field
                filled
                flat
                single-line
                hide-details
                dense
                clearable
                rounded
                v-model="busca"
                @click:clear="limparBusca"
                @keyup.enter="filtrar($event)"
                max-width="300px"
                prepend-inner-icon="search"
                :label="constantes.BOTAO_FILTRAR_EMPRESAS"
              ></v-text-field>
            </v-col>

            <v-col cols="2" sm="7" class="text-right">
              <v-btn class="mr-2" fab small depressed :loading="carregando" @click="carregar">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>

              <v-btn v-if="!isSm" depressed color="primary" @click="adicionar"> NOVA </v-btn>

              <v-btn v-else depressed fab small color="primary" @click="adicionar">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:widget-content>
        <v-data-table :options.sync="options" :headers="headers" :items="empresas" :search="busca" :loading="carregando" mobile-breakpoint="0">
          <template v-slot:item="props">
            <tr>
              <td class="text-no-wrap">{{ props.item.cnpj | formatarCnpj }}</td>
              <td>{{ props.item.razao }}</td>
              <td>{{ props.item.fantasia }}</td>
              <td class="text-center">{{ props.item.certificadoTipo }}</td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">more_vert</v-icon>
                  </template>

                  <v-list dense>
                    <v-list-item @click="editar(props.item)">
                      <v-icon class="mr-4">edit</v-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="changeStatus(props.item)">
                      <v-icon class="mr-4">fact_check</v-icon>
                      <v-list-item-title>Status</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-widget>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import constantes from '@/util/constantes';

export default {
  name: 'ListaEmpresasAdmin',
  data() {
    return {
      headers: [
        { text: 'CNPJ', value: 'cnpj' },
        { text: 'Razão Social', value: 'razao' },
        { text: 'Nome Fantasia', value: 'nomeFantasia' },
        { text: 'Certificado', value: 'certificadoTipo', sortable: false, width: '32px', align: 'center' },
        {
          text: '',
          value: 'acoes',
          align: 'left',
          width: '32px',
          sortable: false,
        },
      ],
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.carregar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('empresa', {
      empresas: 'empresas',
      carregando: 'carregando',
    }),

    isSm() {
      return this.$vuetify.breakpoint.sm;
    },
  },

  methods: {
    adicionar() {
      this.$store.commit('empresa/adicionar');
      this.$router.push('/admin/empresa/adicionar');
    },

    editar(empresa) {
      this.$store.commit('empresa/editar', empresa);
      this.$router.push('/empresa/editar');
    },

    carregar() {
      this.$store.dispatch('empresa/carregarTodas', this.options);
    },

    limparBusca() {
      this.carregar();
    },

    filtrar(event) {
      const newOptions = {
        ...this.options,
        query: this.busca,
      };

      this.$store.dispatch('empresa/carregarTodas', newOptions);
      setTimeout(() => {
        event.target.select();
      }, 300);
    },

    changeStatus(empresa) {
      if (empresa.status === 'Ativa') {
        this.inativar(empresa);
      } else {
        this.ativar(empresa);
      }
    },

    inativar(empresa) {
      this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja inativar?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('empresa/inativar', empresa);
        }
      });
    },

    ativar(empresa) {
      this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja ativar?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('empresa/ativar', empresa);
        }
      });
    },
  },
};
</script>
